<template>
    <div class="p-t-5">
        <h4 class="workplatform-title">资源站点分布</h4>
        <Row v-for="item in stationCount" :key="item.key">
            <i-col span="24">
              {{item.values[0].assetName}}:
                <a class="m-l-5" v-for="station in item.values" :key="station.stationId " @click="handleStationShow(station)">
                    {{station.stationName}}({{station.quantity }})
                </a>
            </i-col>
        </Row>

        <!-- 显示等级分布 -->
        <Row :gutter="8" class="p-b-5">

            <i-col v-for="(item,i) in levelCount" :key="i" span="4">
                <div :class="currentLevel ===item.level?'order-level-active':'order-level'" @click="handleSearchLevel(item)">
                    <span>{{item.levelName}}</span>
                    <span class="right">{{item.quantity}}</span>
                </div>
            </i-col>
        </Row>

        <Row class="table-title p-t-2">
            <i-col span="4">连封名称</i-col>
            <i-col span="4">所属资产</i-col>
            <i-col span="4">所属站台</i-col>
            <i-col span="4">包含资源</i-col>
            <i-col span="4">价格</i-col>
            <i-col span="4">操作</i-col>
        </Row>
        <Row v-for="(resource,index) in resourceList" :key="index" class="p-t-2" :class="computedRowClassName(resource,index)">
            <i-col span="4">{{resource.productskuName}}</i-col>
            <i-col span="4">{{resource.assetName}}</i-col>
            <i-col span="4">{{resource.stationName}}</i-col>
            <i-col span="4">
              <span v-for="child in resource.productDetailResourceVOList" :key="child.resourceCode" class="remark m-r-5" :style="{'font-weight': 'bold'}">
                {{child.resourceCode}}
              </span>
            </i-col>

            <i-col span="4">{{formatMoney(resource.useprice)}}</i-col>
            <i-col span="4">
                <a v-if="!customskuId" class="delete" @click="handleDeleteShow(resource)">删除</a>
            </i-col>
        </Row>

        <div class="paging_style">
            <Page size="small" :total="total" :page-size="query.pageSize" show-total show-elevator @on-change="handlePageNumberChange"></Page>
        </div>

        <!-- 弹窗显示点位的站点分布情况 -->
        <SvgStation ref="svgStation" :stationSvgModal.sync="showStation" :orderId="params.orderId" :stationId="currentStation.stationId"
          :customskuId="customskuId" :searchSchedules="[{startDate: params.startDate, endDate: params.endDate}]" :svgShowType="3"
          showStationPortray
          @svg-finished="svgOrThreeFinished" @handleChange3dMode="handleBeginRevice3d"/>

        <ThreeStation ref="threeStation" @three-finished="svgOrThreeFinished" @handleChangeSvgMode="handleStationShow" />
    </div>
</template>

<script>
import SvgStation from '@/components/svg/SvgStation'
import ThreeStation from '@/components/svg/ThreeStation'

import { toMoney } from '@/utils/wnumb_own'
import { GetCurrentSchedule } from '@/utils/dateFormat'

import { getStationLevelList, getProductDetailPageByOrder, getResourceCountByOrderId } from '@/api/order/productdetail'
import { deleteContinuous, getContinuousPage } from '@/api/order/continuous'

export default {
  props: {
    params: {
      type: Object
    },
    customskuId: {
      type: Number,
      default: null
    }
  },
  components: {
    SvgStation, ThreeStation
  },
  data () {
    return {
      resourceList: [],
      query: { pageNumber: 1, pageSize: 10 },
      total: 0,
      showStation: false,
      levelCount: [],
      stationCount: [],
      currentStation: {},
      currentLevel: null
    }
  },
  created () {
    this.initPageData()
  },
  methods: {
    computedRowClassName (row, index) {
      if (row.conflict) {
        return 'table-conflict-row'
      } else if (index % 2 === 0) {
        return 'table-row-1'
      } else {
        return 'table-row-2'
      }
    },
    initPageData () {
      this.initPurchaseList()
      this.getStationCount()
      this.getLevelCount()
    },
    initPurchaseList () {
      this.query.orderId = this.params.orderId
      this.query.productId = this.params.productId
      this.query.startDate = this.params.startDate
      this.query.customskuId = this.customskuId || undefined
      this.query.endDate = this.params.endDate
      getContinuousPage(this.query).then(res => {
        this.total = res.totalRow
        this.resourceList = res.list
      })
    },
    getStationCount () {
      const stationQuery = {
        orderId: this.params.orderId,
        productId: this.params.productId,
        customskuId: this.customskuId || undefined,
        schedules: JSON.stringify([{ startDate: this.params.startDate, endDate: this.params.endDate }]),
        priceType: this.params.priceType
      }
      getResourceCountByOrderId(stationQuery).then(res => {
        this.$emit('on-update-map', res)
        this.stationCount = []
        const assetMap = [] // 区分资产后的站点信息
        let assetStations = null

        res.forEach(resource => {
          assetStations = assetMap.find(x => x.key === resource.assetId)
          if (assetStations) { // 如果已经存在，那么给值添加资源
            assetStations = assetStations.values.push(Object.assign({}, resource))
          } else {
            assetMap.push({
              key: resource.assetId,
              values: [Object.assign({}, resource)]
            })
          }
          this.stationCount = assetMap
        })
      })
    },
    getLevelCount () {
      const query = this.params
      query.customskuId = this.customskuId || undefined
      const levelCount = new Map()
      getStationLevelList(query).then(res => {
        res.forEach(level => {
          level.stationLevelBeanList.forEach(sl => {
            const count = levelCount.get(sl.levelName) || { levleName: '', quantity: 0 }
            count.quantity += sl.quantity
            levelCount.set(sl.levelName, Object.assign({}, sl, count))
          })
        })
        this.levelCount = [...levelCount.values()]
      })
    },
    handlePageNumberChange (page) {
      this.query.pageNumber = page
      this.getPage()
    },
    handleDeleteShow (resource) {
      this.$Modal.confirm({
        title: '操作提示',
        content: '点击确定后资源将会从您订单里删除，请谨慎操作',
        onOk: () => {
          this.handleDelete(resource)
        }

      })
    },
    handleDelete (resource) {
      const data = {
        orderItemId: resource.orderItemId
      }
      deleteContinuous(data).then(res => {
        if (res && !res.errcode) {
          this.$Notice.success({ desc: '删除成功' })
          this.$emit('on-update-order', this.params.categoryId)
        }
      })
    },
    formatMoney (number) {
      return toMoney(number)
    },
    formatSchedule (start, end) {
      return GetCurrentSchedule(start, end)
    },
    handleStationShow (station) {
      if (station.stationId) {
        this.currentStation = station
      }
      this.showStation = true
    },
    svgOrThreeFinished () {
      if (this.showStation) {
        this.$refs.svgStation.initShowBrand()
      }

      const schedule = JSON.stringify([{ startDate: this.params.startDate, endDate: this.params.endDate }])
      const resourceSearchData = {
        orderId: this.params.orderId,
        stationIds: this.currentStation.stationId,
        schedules: schedule,
        productId: this.params.productId,
        customskuId: this.customskuId,
        pageSize: 200,
        pageNumber: 1
      }

      getProductDetailPageByOrder(resourceSearchData).then(res => {
        if (this.showStation) {
          this.$refs.svgStation.tagResource(res.list)
          this.$refs.svgStation.choseSpinShow()
        } else {
          this.$refs.threeStation.tagResource(res.list)
        }
      })
    },
    handleBeginRevice3d (stationId) {
      this.$refs.threeStation.initData(stationId)
    }
  },
  watch: {
    params: function () {
      this.query.pageNumber = 1
      this.initPageData()
    }
  }
}
</script>
